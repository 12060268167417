import api from './../plugins/httpRequest'
const uri = "/api/config";

const apiDetail = (params)=>{
    return api.get(uri,{params})
}

const apiSave = (params)=>{
    if (params.id){
        return api.put(uri + '/' + params.id, params)
    }else {
        return api.post(uri, params)
    }
}

const apiSystem = ()=>{
    return api.get("/api/system")
}

export default {
    namespaced: true,
    state: {
        detail:{},
        system:{},
        loading:false
    },
    mutations: {
        // 注意，这里可以设置 state 属性，但是不能异步调用，异步操作写到 actions 中
        DETAIL(state, data) {
            state.loading = false
            state.detail = data;
        },
        SYSTEM(state, data) {
            state.loading = false
            state.system = data;
        },
    },
    actions: {
        getConfig({commit,state},params) {
            state.loading = true;
            apiDetail(params).then(function(res) {
                commit('DETAIL', res.data);
            },function() {
                state.loading = false;
            });
        },

        setConfig({commit,state},params) {
            state.loading = true;
            apiSave(params).then(function(res) {
                commit("DETAIL", res.data);
            },function() {
                state.loading = false;
            });
        },

        getSystem({commit,state}) {
            apiSystem().then(function(res) {
                commit('SYSTEM', res.data);
            },function() {
                state.loading = false;
            });
        },
    }
}
