<template>
  <router-view :height="height-125"></router-view>
</template>
<script>
export default {
  props:['height'],
  data() {
    return {
        style:{},
    };
  },
  methods: {
  },
  computed: {

  },
  watch: {
  },
  mounted() {

  }
}
</script>
