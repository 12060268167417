import {createRouter, createWebHistory} from "vue-router"
import { ElNotification } from 'element-plus'
import Main from "../pages/Main.vue"
import Login from "../pages/Login.vue"
import Bridge from "../pages/Breage.vue"


const routers = [
    {
        path: "/login",
        component: Login,
    },
    {
        path: "/",
        component: Main,
        children:[
            {
                path: 'home',
                component: Bridge,
                children:[
                    {
                        path: '',
                        name: 'home',
                        component: () => import ('../pages/CustomPage'),
                        meta:{title: "自定义页面"}
                    },{
                        path: 'create',
                        name: 'pageCreate',
                        component: () => import ('../forms/Page'),
                        meta:{title: "创建页面"}
                    },{
                        path: 'update/:id',
                        name: 'pageUpdate',
                        component: () => import ('../forms/Page'),
                        meta:{title: "更新页面"},
                        props($route){
                            return { id: $route.params.id}
                        }
                    }
                ]
            },
            {
                path: '',
                name: 'index',
                component: () => import ('../pages/Index'),
                meta:{title: "首页"}
            },
            {
                path: 'mHome',
                component: Bridge,
                children:[
                    {
                        path: 'goods',
                        name: 'mHomeGoods',
                        component: () => import ('../pages/mhome/Goods'),
                        meta:{title: "商品信息"}
                    },{
                        path: 'create',
                        name: 'shopCreate',
                        component: () => import ('../pages/shop/Form'),
                        meta:{title: "门店创建"}
                    },{
                        path: 'update/:id',
                        name: 'shopUpdate',
                        component: () => import ('../pages/shop/Form'),
                        meta:{title: "门店创建"},
                        props: true
                    },
                    {
                        path: 'goods-type',
                        name: 'mHomeGoodsType',
                        component: () => import ('../pages/mhome/GoodsType'),
                        meta:{title: "商品信息"}
                    },
                    {
                        path: 'community',
                        name: 'mHomeCommunity',
                        component: () => import ('../pages/mhome/Community'),
                        meta:{title: "社区信息"}
                    },
                    {
                        path: 'reservation',
                        name: 'mHomeReservation',
                        component: () => import ('../pages/mhome/Reservation'),
                        meta:{title: "预约管理"}
                    }
                ]
            },
            {
                path: 'shops',
                component: Bridge,
                children:[
                    {
                        path: '',
                        name: 'shops',
                        component: () => import ('../pages/shop/Index'),
                        meta:{title: "门店信息"}
                    },{
                        path: 'create',
                        name: 'shopCreate',
                        component: () => import ('../pages/shop/Form'),
                        meta:{title: "门店创建"}
                    },{
                        path: 'update/:id',
                        name: 'shopUpdate',
                        component: () => import ('../pages/shop/Form'),
                        meta:{title: "门店创建"},
                        props: true
                    }
                ]
            },
            {
                path: 'orders',
                component: Bridge,
                children:[
                    {
                        path: '',
                        name: 'orders',
                        component: () => import ('../pages/Orders'),
                        meta:{title: "订单列表"}
                    },{
                        path: ':id',
                        name: 'orderDetail',
                        component: () => import ('../pages/Detail'),
                        meta:{title: "订单详情"},
                        props($route){
                            return { label: '订单详情',name: '订单详情#1123', tab: 'Order',id: $route.params.id}
                        }
                    }
                ]
            },
            {
                path: 'products',
                component: Bridge,
                children:[
                    {
                        path: '',
                        name: 'products',
                        component: () => import ('../pages/Products'),
                        meta:{title: "商品列表"}
                    },{
                        path: ':id',
                        name: 'productUpdate',
                        component: () => import ('../pages/Detail'),
                        meta:{title: "商品详情"},
                        props($route){
                            return { label: '商品详情',name: '商品详情#1123', tab: 'Product',id: $route.params.id}
                        }
                    }
                ]
            },
            {
                path: 'users',
                component: Bridge,
                children:[
                    {
                        path: '',
                        name: 'users',
                        component: () => import ('../pages/User'),
                        meta:{title: "用户管理"}
                    },{
                        path: ':id',
                        name: 'userDetail',
                        component: () => import ('../pages/Detail'),
                        meta:{title: "用户详情"},
                        props($route){
                            return { label: '用户详情',name: '用户详情#1123', tab: 'User',id: $route.params.id}
                        }
                    }
                ]
            },
            {
                path: 'commission',
                component: Bridge,
                children:[
                    {
                        path: 'agent',
                        name: 'commissionAgent',
                        component: () => import ('../pages/commission/Agent'),
                        meta:{title: "分销等级设置"}
                    },{
                        path: 'users',
                        name: 'commissionUsers',
                        component: () => import ('../pages/commission/Users'),
                        meta:{title: "分销人员管理"}
                    },{
                        path: 'shops',
                        name: 'commissionShops',
                        component: () => import ('../pages/commission/Shops'),
                        meta:{title: "分销门店"},
                        props: true
                    }
                ]
            },
            {
                path: 'admin',
                name: 'admin',
                component: () => import ('../pages/Admin'),
                //props: route => ({ id: route.query.id }),
                meta:{title: "账号信息"}
            },
            {
                path: 'auth',
                component: Bridge,
                children:[
                    {
                        path: 'menus',
                        name: 'menus',
                        component: () => import ('../pages/auth/Menus'),
                        meta:{title: "菜单信息"}
                    },
                    {
                        path: 'users',
                        name: 'auth-users',
                        component: () => import ('../pages/auth/Users'),
                        meta:{title: "账号信息"}
                    },
                    {
                        path: 'roles',
                        name: 'roles',
                        component: () => import ('../pages/auth/Roles'),
                        meta:{title: "角色信息"}
                    },
                ]
            },

            {
                path: 'config',
                component: Bridge,
                children:[
                    {
                        path: 'setting',
                        name: 'config/setting',
                        component: () => import ('../pages/Config'),
                        meta:{ title: "系统配置" }
                    },
                ]
            },
        ]
    }
];


const router = createRouter({
    history: createWebHistory(),// 4. 内部提供了 history 模式的实现。为了简单起见，我们在这里使用 hash 模式。
    routes: routers, // `routes: routes` 的缩写
})
//拦截器
router.beforeEach((to, from, next) => {
    if (to.meta.title){
        document.title = to.meta.title
    }
    if (to.matched.length ===0) {  //如果未匹配到路由
        ElNotification({
            title: "Error",
            message: '页面' + to.path + '未找到，返回上一级！',
            duration:1500,
            type: 'error'
        });

    } else {
        next();    //如果匹配到正确跳转
    }
});
export default router;