<template>
  <div id="login">
    <div class="login-header">
      <h4>{{ title }}</h4>
    </div>
    <el-form :model="ruleForm" status-icon :rules="rules" size="large" ref="ruleForm" class="demo-ruleForm" v-loading="loading">
      <el-form-item prop="email">
        <el-input prefix-icon="User" type="text" v-model="ruleForm.email" autocomplete="off" placeholder="请输入账号"></el-input>
      </el-form-item>
      <el-form-item prop="password">
        <el-input prefix-icon="Key" type="password" v-model="ruleForm.password" autocomplete="off" placeholder="请输入密码" @keyup.native.enter="submitForm('ruleForm')" ></el-input>
      </el-form-item>
      <el-form-item>
        <div class="between">
          <el-button class="w90"  type="primary" @click="submitForm('ruleForm')">提交</el-button>
          <el-button class="w90" @click="resetForm('ruleForm')">重置</el-button>
        </div>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
import { mapState, mapActions } from 'vuex';
export default {
  data() {
    let checkUser = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('账号不能为空！'));
      }else {
        callback();
      }
    };
    let validatePass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('密码不能为空！'));
      } else {
        callback();
      }
    };
    return {
      title:"",
      ruleForm: {
        email: '',
        password: '',
      },
      rules: {
        password: [
          { validator: validatePass, trigger: 'blur' }
        ],
        email: [
          { validator: checkUser, trigger: 'blur' }
        ]
      }
    };
  },
  methods: {
    ...mapActions('Login',[
      'login'
    ]),
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.login(this.ruleForm);
        } else {
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    }
  },
  computed: mapState('Login',{
    loading: state => state.loading
  }),
  watch: {
    loading: function () {
      let user = sessionStorage.getItem("user");
      if(user){
        this.$router.push({name: "index"});
      }
    }
  },
  mounted() {
    sessionStorage.removeItem("user");
    let tv = setInterval(()=>{
        let system = JSON.parse(sessionStorage.getItem('system'));
        if (system){
            this.title = system.name;
          clearInterval(tv);
        }
    },200);

  }
}
</script>
<style scoped>
#login{
  width: 350px;
  margin: 0 auto;
  padding-top: 25vh;
}
.login-header{
  margin: 0 auto;
}

h4{
  text-align: center;
  margin: 0 0 10px 0;
}
.between{
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.w90{
  width: 90px;
}
</style>
