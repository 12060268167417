import api from './../plugins/httpRequest'

const uri = "api/auth-roles";

const getRole = (params)=>{
    let {id} = params?params:{};
    if (id){
        return api.get(uri+"/"+id)
    }else {
        return api.get(uri,{params})
    }
}
const setRole = (params)=>{
    let {id} = params?params:{};
    if (id){
        return api.put(uri+"/"+id,params)
    }else {
        return api.post(uri,params)
    }
}
const delRole = (id)=>{
    if (id){
        return api.delete(uri+"/"+id)
    }
}

const getOption = (params)=>{
    return api.get("api/auth-permissions",params);
}

export default {
    namespaced: true,
    state: {
        list:[],
        loading:false,
        detailLoading:false,
        option:[],
        detail:{}
    },
    mutations: {
        // 注意，这里可以设置 state 属性，但是不能异步调用，异步操作写到 actions 中
        LIST(state, result) {
            state.loading = false
            state.list = result;
        },
        UPDATE(state, data) {
            state.list = state.list.map((item)=>{
                return item.id === data.id ? data : item;
            })
            state.loading = false;
        },
        CREATE(state, data) {
            state.list.unshift(data);
            state.loading = false;
        },
        DETAIL(state, data) {
            state.detailLoading = false
            state.detail = data;
        },
        DELETE(state, id) {
            state.list = state.list.filter(item =>item.id !== id);
            state.loading = false;
        },
        OPTION(state, result) {
            state.option = result.data;
        }
    },
    actions: {
        getRole({commit,state},params) {
            let ret = params && params.id;
            if (ret){
                state.detailLoading = true;
            }else {
                state.loading = true;
            }
            getRole(params).then(function(res) {
                if (ret){
                    commit('DETAIL', res.data);
                }else {
                    commit('LIST', res.data);
                }
            });
        },
        setRole({commit,state},params) {
            state.loading = true;
            setRole(params).then(function(res) {
                if (params && params.id){
                    commit('UPDATE', res.data);
                }else {
                    commit('CREATE', res.data);
                }
            });
        },
        delRole({commit,state},id) {
            state.loading = true;
            delRole(id).then(function(res) {
                commit('DELETE', res.data);
            });
        },
        getOption({commit}) {
            getOption({per_page:100}).then(function(res) {
                commit('OPTION', res.data);
            });
        },
    }
}
