import api from './../plugins/httpRequest'
const uri = "api/auth-menus";
const getMenu = (params)=>{
    let id = params?params.id:null;
    if (id){
        return api.get(uri+"/"+id)
    }else {
        return api.get(uri,{params})
    }
}
const setMenu = (params)=>{
    let id = params?params.id:null;
    if (id){
        return api.put(uri+"/"+id,params)
    }else {
        return api.post(uri,params)
    }
}
const delMenu = (params)=>{
    let id = params?params.id:null;
    if (id){
        return api.delete(uri+"/"+id)
    }else {
        return api.delete(uri,params)
    }
}

const getOptions = ()=>{
    return api.get("/api/menus-options");
}

const updateItem = (params)=>{
    return params.map(function (item){
        if (item.icon){
            item.icon = item.icon.split("-").pop();
        }
        if (item.children){
            item.children = updateItem(item.children);
        }
        return item;
    })
}

export default {
    namespaced: true,
    state: {
        menu:[],
        detail:{
            region:"",
            name:"sad"
        },
        loading:false,
        detailLoading:false,
        refresh:false,
        options:[]
    },
    mutations: {
        // 注意，这里可以设置 state 属性，但是不能异步调用，异步操作写到 actions 中
        LIST(state, result) {
            state.loading = false;
            state.menu = updateItem(result.data);
        },
        DETAIL(state, data) {
            state.detailLoading = false;
            state.detail = data;
        },
        OPTIONS(state, data){
            state.options = data;
        }
    },
    actions: {

        getMenu({commit,state},params={}) {
            const { id } = params;
            if (id){
                state.detailLoading = true;
            }else {
                state.loading = true;
            }
            getMenu(params).then(function(res) {
                commit(id ? 'DETAIL' :'LIST', res.data);
            });
        },

        setMenu({commit,state},params) {
            state.refresh = true;
            setMenu(params).then(function(res) {
                commit('DETAIL', res.data);
            },function() {
                state.refresh = false;
            });
        },

        delMenu({commit,state},params) {
            state.refresh = true;
            delMenu(params).then(function(res) {
                commit('DETAIL', res.data);
            },function() {
                state.refresh = false;
            });
        },

        getOptions({commit,state}){
            getOptions().then(function(res) {
                commit('OPTIONS', res.data);
            },function() {

            });
        }
    }
}
